<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="deadlines"
      no-data-text="Keine bevorstehenden Fristen"
      sort-by="deadline"
    >
      <template v-slot:top>
        <slot name="top">
          <v-card flat>
            <v-card-title class="body-2 v-card__subtitle"
              ><v-icon left small class="mr-2">mdi-timer-sand</v-icon>Fristen
            </v-card-title>
          </v-card>
        </slot>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <data-chip :text="getStatusText(item.deadline)" small></data-chip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DataChip from "@/components/_systemwide/DataChip.vue";
export default {
  name: "personnel-records-deadlines-data-table",
  props: {
    deadlines: {
      type: Array,
      required: true,
    },
  },
  components: { DataChip },
  data() {
    return {
      headers: [
        { text: "Wert", value: "type" },
        { text: "Frist", value: "deadline" },
        { text: "Status", value: "status" },
      ],
    };
  },

  methods: {
    getStatusText(deadlineTimestamp) {
      const currentDate = new Date();
      const deadline = deadlineTimestamp.toDate();

      if (deadline < currentDate) {
        return "Abgelaufen";
      } else {
        return "Gültig";
      }
    },
  },
};
</script>
