<template>
  <div>
    <v-card flat>
      <v-card-title class="body-2 v-card__subtitle">
        <slot name="top">
          <div v-if="headerTitle">
            <v-icon left small class="mr-2">{{ headerIcon }}</v-icon
            >{{ headerTitle }}
          </div>
        </slot>
        <v-chip-group
          v-model="activeDataViewValue"
          mandatory
          active-class="primary--text"
        >
          <v-chip
            v-for="view in dataViews"
            :key="view.value"
            label
            :value="view.value"
            class="transparent-background font-weight-medium mr-2"
          >
            {{ view.text }}
            <!-- <v-icon right> mdi-chevron-down </v-icon> -->
          </v-chip>
        </v-chip-group>

        <v-spacer> </v-spacer>
        <!-- <v-badge
          :value="searchValue !== ''"
          bordered
          color="primary"
          overlap
          dot
          bottom
          class="mr-3"
        >
          <v-btn depressed @click="toggleSearch()">
            <v-icon> mdi-magnify </v-icon>
          </v-btn>
        </v-badge> -->
        <!-- <v-btn depressed class="mr-2">
          <v-icon> mdi-filter-variant </v-icon>
        </v-btn> -->

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn depressed disabled v-bind="attrs" v-on="on" class="mr-2">
              <v-icon> mdi-swap-vertical </v-icon>
            </v-btn>
            <!-- <v-btn depressed v-bind="attrs" v-on="on" class="mr-4">
              <span>
                Gruppieren nach <strong>{{ groupBy }}</strong>
              </span>
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn> -->
          </template>
          <v-list nav>
            <v-list-item-group v-model="groupBy" mandatory>
              <v-list-item link dense value="Datum">
                <v-list-item-icon>
                  <v-icon dense>mdi-calendar-start</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Datum</v-list-item-title>
              </v-list-item>
              <v-list-item link dense value="Funktion">
                <v-list-item-icon>
                  <v-icon dense>mdi-puzzle-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Funktion</v-list-item-title>
              </v-list-item>
              <v-list-item link dense value="Organisation">
                <v-list-item-icon>
                  <v-icon dense>mdi-office-building</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Organisation</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <table-column-selector-menu
          v-if="showTableColumnSelector"
          v-model="tableHeaders"
          :headers="allColumnHeaders"
        >
        </table-column-selector-menu>
      </v-card-title>
      <v-card-text v-if="search.isOpen || filter.isOpen">
        <v-row>
          <v-col v-if="search.isOpen">
            <v-text-field
              v-model="searchValue"
              outlined
              :label="searchTitle"
              dense
              clearable
              hide-details="auto"
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </v-col>
          <!-- <v-col v-if="filter.isOpen" cols="12" sm="4">
            <v-select
              outlined
              label=""
              multiple
              dense
              hide-details="auto"
            ></v-select>
          </v-col>
          <v-col v-if="filter.isOpen" cols="12" sm="4">
            <v-select outlined label="" multiple dense hide-details="auto">
              <template v-slot:selection="{ item, index }">
                <personnel-record-status-chip
                  v-if="index < 2"
                  small
                  :status="item.text"
                ></personnel-record-status-chip>
                <span v-if="index === 2" class="grey--text text-caption">
                  (+{{ statusOptions.length - 2 }} weitere)
                </span>
              </template>
            </v-select>
          </v-col> -->
          <v-spacer></v-spacer>
          <v-col class="text-right"
            ><v-btn depressed @click="toggleExportDialog" disabled
              ><v-icon left>mdi-database-export</v-icon>Exportieren</v-btn
            ></v-col
          >
        </v-row>
      </v-card-text>
    </v-card>
    <v-divider> </v-divider>
  </div>
</template>

<style></style>

<script>
import TableColumnSelectorMenu from "@/components/_system/helpers/TableColumnSelectorMenu.vue";
export default {
  name: "table-header",
  props: {
    headerIcon: {
      type: String,
      default: "",
    },
    headerTitle: {
      type: String,
      default: "",
    },
    dataViews: {
      type: Array,
      default: () => [{ text: "Alle", value: "all" }],
      required: true,
    },
    activeDataViewValue: {
      type: String,
      default: "all",
    },
    searchTitle: {
      type: String,
      default: "Suchen",
    },
    searchValue: {
      type: String,
      default: "",
    },
    showTableColumnSelector: {
      type: Boolean,
      default: false,
    },
    allColumnHeaders: {
      type: Array,
      default: () => [],
    },
    tableHeaders: {
      type: Array,
      default: () => [],
    },
  },
  components: { TableColumnSelectorMenu },

  data() {
    return {
      groupBy: "Datum",
      search: {
        isOpen: true,
      },
      filter: {
        isOpen: false,
      },
    };
  },
  watch: {
    searchValue(newVal) {
      this.$emit("search-updated", newVal);
    },
    activeDataViewValue(newVal) {
      this.$emit("active-data-view-updated", newVal);
    },
    tableHeaders(newVal) {
      this.$emit("table-headers-updated", newVal);
    },
  },
  methods: {
    toggleSearch() {
      this.search.isOpen = !this.search.isOpen;
    },
  },
};
</script>
