<template>
  <v-card>
    <v-card-title class="body-2 v-card__subtitle"
      ><v-icon left small class="mr-2">mdi-card-account-details</v-icon
      >{{
        type === "personal"
          ? "Privat"
          : type === "business"
          ? "Geschäftlich (Arbeitgeber)"
          : "Dienstlich"
      }}</v-card-title
    >
    <v-card-text>
      <v-row class="match-height" dense>
        <v-col v-if="!hideAdress" cols="12" sm="12" md="6">
          <v-card outlined>
            <v-card-text v-if="noAdressData">
              Keine Adresse hinterlegt
            </v-card-text>
            <v-card-text v-else>
              {{ contact.name }}
              <br v-if="contact.name" />
              {{ contact.address.street }}, {{ contact.address.streetNumber }}
              <br />
              {{ contact.address.postalCode }} {{ contact.address.city }}
              <br />

              <v-btn
                small
                depressed
                :href="
                  'https://maps.apple.com/?q=' +
                  contact.address.street +
                  ' ' +
                  contact.address.streetNumber +
                  ' ' +
                  contact.address.postalCode +
                  ' ' +
                  contact.address.city
                "
                target="_blank"
                rel="noopener noreferrer"
                class="mt-2"
              >
                <v-icon left>mdi-map-marker-circle</v-icon>In Apple Maps
                öffnen</v-btn
              >

              <v-btn
                small
                depressed
                :href="
                  'https://www.google.com/maps/search/' +
                  contact.address.street +
                  ' ' +
                  contact.address.streetNumber +
                  ' ' +
                  contact.address.postalCode +
                  ' ' +
                  contact.address.city
                "
                target="_blank"
                rel="noopener noreferrer"
                class="mt-2"
              >
                <v-icon left>mdi-google-maps</v-icon>In Google Maps
                öffnen</v-btn
              >
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" :md="!hideAdress ? '6' : '12'">
          <v-card outlined>
            <v-card-text v-if="noAdressData">
              Keine Erreichbarkeit hinterlegt
            </v-card-text>
            <v-card-text v-else>
              <v-list-item v-if="contact.phone">
                <v-list-item-icon class="mr-6"
                  ><v-icon>mdi-phone</v-icon></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-subtitle
                    ><a
                      :href="'tel:' + contact.phone"
                      class="text-decoration-none"
                      >{{ phoneNumberFormatter(contact.phone) }}</a
                    ></v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="contact.mobile">
                <v-list-item-icon class="mr-6"
                  ><v-icon>mdi-cellphone</v-icon></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <a
                      :href="'tel:' + contact.mobile"
                      class="text-decoration-none"
                      >{{ phoneNumberFormatter(contact.mobile) }}</a
                    >
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="contact.email">
                <v-list-item-icon class="mr-6"
                  ><v-icon>mdi-at</v-icon></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-subtitle
                    ><a
                      :href="'mailto:' + contact.email"
                      class="text-decoration-none"
                      >{{ contact.email }}</a
                    >
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="contact.fax">
                <v-list-item-icon class="mr-6"
                  ><v-icon>mdi-fax</v-icon></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-subtitle>{{
                    phoneNumberFormatter(contact.fax)
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "personnel-record-contact-card",

  props: {
    contact: {
      type: Object,
      required: true,
    },
    hideAdress: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
      validator: function (value) {
        return ["personal", "business"].includes(value);
      },
    },
  },
  computed: {
    noAdressData() {
      return (
        !this.contact.address ||
        !this.contact.address.street ||
        !this.contact.address.streetNumber ||
        !this.contact.address.postalCode ||
        !this.contact.address.city
      );
    },
    noContactData() {
      return (
        !this.contact.phone &&
        !this.contact.mobile &&
        !this.contact.email &&
        !this.contact.fax
      );
    },
  },

  methods: {
    phoneNumberFormatter(number) {
      // Entfernt zuerst alle Leerzeichen, um eine einheitliche Basis zu schaffen
      number = number.replace(/\s+/g, "");

      // Überprüft und formatiert internationale Vorwahlen (+49 oder 0049)
      number = number.replace(/(\+49|0049)/, "$1 ");

      // Formatierung für Mobilnummern (0000 123456)
      if (number.match(/(\+49|0049)?\d{10}/)) {
        return number.replace(/(\d{4})(\d{6})/, "$1 $2");
      }
      // Anpassung der Logik für Festnetznummern, um korrekt mit Nummern umzugehen, die weniger als 11 Ziffern haben
      else if (number.match(/(\+49|0049)?\d{7,}/)) {
        // Überprüft, ob die Nummer genau 10 Ziffern hat, und wendet eine spezielle Formatierung an
        if (number.replace(/(\+49|0049)\s?/, "").length === 10) {
          return number.replace(/(\d{4})(\d+)/, "$1 $2");
        } else {
          // Standardformatierung für Festnetznummern mit mehr oder weniger als 10 Ziffern
          return number.replace(/(\d{3})(\d+)/, "$1 $2");
        }
      }
      // Gibt die Nummer unverändert zurück, falls sie keinem Muster entspricht
      return number;
    },
  },
};
</script>
