<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" sm="6"
          ><v-card>
            <v-card-title class="body-2 v-card__subtitle"
              ><v-icon left small class="mr-2">mdi-account-child</v-icon
              >Erziehungsberechtigte Person 1</v-card-title
            >
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-select
                      v-model="recordYouthGroupLocal.guardian1.salutation"
                      label="Anrede"
                      :items="salutationOptions"
                      hide-details="auto"
                      clearable
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-select
                      v-model="recordYouthGroupLocal.guardian1.letterSalutation"
                      label="Briefanrede"
                      :items="letterSalutationOptions"
                      hide-details="auto"
                      clearable
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-select
                      v-model="recordYouthGroupLocal.guardian1.title"
                      label="Titel"
                      :items="titleOptions"
                      clearable
                      hide-details="auto"
                      outlined
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model.trim="recordYouthGroupLocal.guardian1.firstName"
                      label="Vorname"
                      :rules="[rules.required]"
                      hide-details="auto"
                      outlined
                    ></v-text-field
                  ></v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model.trim="recordYouthGroupLocal.guardian1.lastName"
                      label="Nachname"
                      :rules="[rules.required]"
                      hide-details="auto"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model.trim="
                        recordYouthGroupLocal.guardian1.contact.phone
                      "
                      label="Telefon"
                      hide-details="auto"
                      outlined
                      prepend-inner-icon="mdi-phone"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model.trim="
                        recordYouthGroupLocal.guardian1.contact.mobile
                      "
                      label="Mobil"
                      hide-details="auto"
                      outlined
                      prepend-inner-icon="mdi-cellphone"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model.trim="
                        recordYouthGroupLocal.guardian1.contact.email
                      "
                      label="E-Mail"
                      hide-details="auto"
                      outlined
                      prepend-inner-icon="mdi-at"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="9" sm="9">
                    <v-text-field
                      v-model.trim="
                        recordYouthGroupLocal.guardian1.adress.street
                      "
                      label="Straße"
                      hide-details="auto"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" sm="3">
                    <v-text-field
                      v-model.trim="
                        recordYouthGroupLocal.guardian1.adress.houseNumber
                      "
                      label="Nr."
                      hide-details="auto"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="5" sm="5">
                    <v-text-field
                      v-model.trim="
                        recordYouthGroupLocal.guardian1.adress.zipCode
                      "
                      label="Postleitzahl"
                      hide-details="auto"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="7" sm="7">
                    <v-text-field
                      v-model.trim="recordYouthGroupLocal.guardian1.adress.city"
                      label="Stadt"
                      hide-details="auto"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text> </v-card
        ></v-col>
        <v-col cols="12" sm="6"
          ><v-card>
            <v-card-title class="body-2 v-card__subtitle"
              ><v-icon left small class="mr-2">mdi-account-child</v-icon
              >Erziehungsberechtigte Person 2</v-card-title
            >
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-select
                      v-model="recordYouthGroupLocal.guardian2.salutation"
                      label="Anrede"
                      :items="salutationOptions"
                      hide-details="auto"
                      clearable
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-select
                      v-model="recordYouthGroupLocal.guardian2.letterSalutation"
                      label="Briefanrede"
                      :items="letterSalutationOptions"
                      hide-details="auto"
                      clearable
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-select
                      v-model="recordYouthGroupLocal.guardian2.title"
                      label="Titel"
                      :items="titleOptions"
                      clearable
                      hide-details="auto"
                      outlined
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model.trim="recordYouthGroupLocal.guardian2.firstName"
                      label="Vorname"
                      :rules="[rules.required]"
                      hide-details="auto"
                      outlined
                    ></v-text-field
                  ></v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model.trim="recordYouthGroupLocal.guardian2.lastName"
                      label="Nachname"
                      :rules="[rules.required]"
                      hide-details="auto"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model.trim="
                        recordYouthGroupLocal.guardian2.contact.phone
                      "
                      label="Telefon"
                      hide-details="auto"
                      outlined
                      prepend-inner-icon="mdi-phone"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model.trim="
                        recordYouthGroupLocal.guardian2.contact.mobile
                      "
                      label="Mobil"
                      hide-details="auto"
                      outlined
                      prepend-inner-icon="mdi-cellphone"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model.trim="
                        recordYouthGroupLocal.guardian2.contact.email
                      "
                      label="E-Mail"
                      hide-details="auto"
                      outlined
                      prepend-inner-icon="mdi-at"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="9" sm="9">
                    <v-text-field
                      v-model.trim="
                        recordYouthGroupLocal.guardian2.adress.street
                      "
                      label="Straße"
                      hide-details="auto"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" sm="3">
                    <v-text-field
                      v-model.trim="
                        recordYouthGroupLocal.guardian2.adress.houseNumber
                      "
                      label="Nr."
                      hide-details="auto"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="5" sm="5">
                    <v-text-field
                      v-model.trim="
                        recordYouthGroupLocal.guardian2.adress.zipCode
                      "
                      label="Postleitzahl"
                      hide-details="auto"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="7" sm="7">
                    <v-text-field
                      v-model.trim="recordYouthGroupLocal.guardian2.adress.city"
                      label="Stadt"
                      hide-details="auto"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text> </v-card
        ></v-col>
        <v-col cols="12" sm="6"
          ><v-card>
            <v-card-title class="body-2 v-card__subtitle"
              ><v-icon left small class="mr-2">mdi-town-hall</v-icon>Schule /
              Einrichtung</v-card-title
            >
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model.trim="recordYouthGroupLocal.school.name"
                      label="Name / Beschreibung"
                      hide-details="auto"
                      outlined
                    ></v-text-field
                  ></v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model.trim="recordYouthGroupLocal.school.contactPerson"
                      label="Ansprechperson"
                      hide-details="auto"
                      outlined
                    ></v-text-field
                  ></v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model.trim="recordYouthGroupLocal.school.contact.phone"
                      label="Telefon"
                      hide-details="auto"
                      outlined
                      prepend-inner-icon="mdi-phone"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model.trim="recordYouthGroupLocal.school.contact.email"
                      label="E-Mail"
                      hide-details="auto"
                      outlined
                      prepend-inner-icon="mdi-at"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="9" sm="9">
                    <v-text-field
                      v-model.trim="recordYouthGroupLocal.school.adress.street"
                      label="Straße"
                      hide-details="auto"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" sm="3">
                    <v-text-field
                      v-model.trim="
                        recordYouthGroupLocal.school.adress.houseNumber
                      "
                      label="Nr."
                      hide-details="auto"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="5" sm="5">
                    <v-text-field
                      v-model.trim="recordYouthGroupLocal.school.adress.zipCode"
                      label="Postleitzahl"
                      hide-details="auto"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="7" sm="7">
                    <v-text-field
                      v-model.trim="recordYouthGroupLocal.school.adress.city"
                      label="Stadt"
                      hide-details="auto"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-card-title class="body-2 v-card__subtitle"
              ><v-icon left small class="mr-2"
                >mdi-dots-horizontal-circle</v-icon
              >Sonstiges</v-card-title
            >
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      v-model.trim="recordYouthGroupLocal.note"
                      label="Bemerkungen"
                      hide-details="auto"
                      outlined
                      :rows="3"
                      auto-grow
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "member-management-personnel-records-details-youth-group",
  components: {},
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
      salutationOptions: [
        { text: "Herr", value: "Herr" },
        { text: "Frau", value: "Frau" },
      ],
      letterSalutationOptions: [
        { text: "Herrn", value: "Herrn" },
        { text: "Frau", value: "Frau" },
      ],
      titleOptions: [
        { text: "Dr.", value: "Dr." },
        { text: "Prof.", value: "Prof." },
        { text: "Prof. Dr.", value: "Prof. Dr." },
      ],
    };
  },
  computed: {},
};
</script>

<style></style>
