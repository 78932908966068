<template>
  <div>
    <section>
      <v-container>
        <v-row class="match-height">
          <v-col cols="12" sm="6">
            <v-form
              v-model="helpers.personalContactInfoForm.isValid"
              ref="personalContactInfoForm"
              @submit.prevent="updatePersonalContactInfo"
            >
              <v-card
                :loading="helpers.personalContactInfoForm.isLoading"
                :disabled="helpers.personalContactInfoForm.isLoading"
              >
                <v-card-title class="body-2 v-card__subtitle"
                  ><v-icon left small class="mr-2"
                    >mdi-card-account-details</v-icon
                  >Private Kontaktdaten</v-card-title
                >
                <v-card-text class="mb-15 pb-9">
                  <v-container>
                    <v-row>
                      <v-col cols="9" sm="9">
                        <v-text-field
                          v-model.trim="data.personal.address.street"
                          label="Straße"
                          hide-details="auto"
                          outlined
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3" sm="3">
                        <v-text-field
                          v-model.trim="data.personal.address.streetNumber"
                          label="Nr."
                          hide-details="auto"
                          outlined
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="5" sm="5">
                        <v-text-field
                          v-model.trim="data.personal.address.postalCode"
                          label="Postleitzahl"
                          :rules="[rules.postalCode]"
                          hide-details="auto"
                          outlined
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="7" sm="7">
                        <v-text-field
                          v-model.trim="data.personal.address.city"
                          label="Stadt"
                          hide-details="auto"
                          outlined
                          clearable
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-divider class="mt-15"></v-divider>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model.trim="data.personal.phone"
                          label="Telefon"
                          hide-details="auto"
                          :rules="[rules.mobile]"
                          outlined
                          prepend-inner-icon="mdi-phone"
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model.trim="data.personal.mobile"
                          label="Mobil"
                          hide-details="auto"
                          :rules="[rules.mobile]"
                          outlined
                          prepend-inner-icon="mdi-cellphone"
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model.trim="data.personal.email"
                          label="E-Mail"
                          hide-details="auto"
                          outlined
                          type="email"
                          :rules="[rules.email]"
                          prepend-inner-icon="mdi-at"
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model.trim="data.personal.fax"
                          label="Fax"
                          :rules="[rules.mobile]"
                          hide-details="auto"
                          outlined
                          prepend-inner-icon="mdi-fax"
                          clearable
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      depressed
                      color="success"
                      :loading="helpers.personalContactInfoForm.isLoading"
                      :disabled="!helpers.personalContactInfoForm.isValid"
                      @click="updatePersonalContactInfo()"
                    >
                      <v-icon left> mdi-content-save </v-icon>
                      Speichern
                    </v-btn>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-form>
          </v-col>

          <v-col cols="12" sm="6">
            <v-form
              v-model="helpers.businessContactInfoForm.isValid"
              ref="businessContactInfoForm"
              @submit.prevent="updateBusinessContactInfo"
            >
              <v-card
                :loading="helpers.businessContactInfoForm.isLoading"
                :disabled="helpers.businessContactInfoForm.isLoading"
              >
                <v-card-title class="body-2 v-card__subtitle"
                  ><v-icon left small class="mr-2">mdi-briefcase</v-icon
                  >Geschäftliche Kontaktdaten</v-card-title
                >
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model.trim="data.business.name"
                          label="Name des Unternehmens"
                          hide-details="auto"
                          outlined
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="9" sm="9">
                        <v-text-field
                          v-model.trim="data.business.address.street"
                          label="Straße"
                          hide-details="auto"
                          outlined
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3" sm="3">
                        <v-text-field
                          v-model.trim="data.business.address.streetNumber"
                          label="Nr."
                          hide-details="auto"
                          outlined
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="5" sm="5">
                        <v-text-field
                          v-model.trim="data.business.address.postalCode"
                          :rules="[rules.postalCode]"
                          label="Postleitzahl"
                          hide-details="auto"
                          outlined
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="7" sm="7">
                        <v-text-field
                          v-model.trim="data.business.address.city"
                          label="Stadt"
                          hide-details="auto"
                          outlined
                          clearable
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-divider></v-divider>

                <v-card-text>
                  <v-container
                    ><v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model.trim="data.business.phone"
                          label="Telefon"
                          :rules="[rules.mobile]"
                          hide-details="auto"
                          outlined
                          prepend-inner-icon="mdi-phone"
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model.trim="data.business.mobile"
                          label="Mobil"
                          hide-details="auto"
                          outlined
                          :rules="[rules.mobile]"
                          prepend-inner-icon="mdi-cellphone"
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model.trim="data.business.email"
                          label="E-Mail"
                          type="email"
                          hide-details="auto"
                          outlined
                          :rules="[rules.email]"
                          prepend-inner-icon="mdi-at"
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model.trim="data.business.fax"
                          label="Fax"
                          hide-details="auto"
                          outlined
                          :rules="[rules.mobile]"
                          prepend-inner-icon="mdi-fax"
                          clearable
                        ></v-text-field>
                      </v-col> </v-row
                  ></v-container>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      depressed
                      color="success"
                      :loading="helpers.businessContactInfoForm.isLoading"
                      :disabled="!helpers.businessContactInfoForm.isValid"
                      @click="updateBusinessContactInfo()"
                    >
                      <v-icon left> mdi-content-save </v-icon>
                      Speichern
                    </v-btn>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-form>
          </v-col>
          <v-col cols="12">
            <v-form
              v-model="helpers.stationContactInfoForm.isValid"
              ref="stationContactInfoForm"
              @submit.prevent="updateStationContactInfo"
            >
              <v-card
                :loading="helpers.stationContactInfoForm.isLoading"
                :disabled="helpers.stationContactInfoForm.isLoading"
              >
                <v-card-title class="body-2 v-card__subtitle"
                  ><v-icon left small class="mr-2">mdi-fire-station</v-icon
                  >Dienstliche Kontaktdaten (Organisation)</v-card-title
                >
                <v-card-text>
                  <v-container
                    ><v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model.trim="data.station.phone"
                          label="Telefon"
                          :rules="[rules.mobile]"
                          hide-details="auto"
                          outlined
                          prepend-inner-icon="mdi-phone"
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model.trim="data.station.mobile"
                          label="Mobil"
                          :rules="[rules.mobile]"
                          hide-details="auto"
                          outlined
                          prepend-inner-icon="mdi-cellphone"
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model.trim="data.station.email"
                          label="E-Mail"
                          :rules="[rules.email]"
                          hide-details="auto"
                          outlined
                          prepend-inner-icon="mdi-at"
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model.trim="data.station.fax"
                          label="Fax"
                          :rules="[rules.mobile]"
                          hide-details="auto"
                          outlined
                          prepend-inner-icon="mdi-fax"
                          clearable
                        ></v-text-field>
                      </v-col> </v-row
                  ></v-container>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      depressed
                      color="success"
                      :loading="helpers.stationContactInfoForm.isLoading"
                      :disabled="!helpers.stationContactInfoForm.isValid"
                      @click="updateStationContactInfo()"
                    >
                      <v-icon left> mdi-content-save </v-icon>
                      Speichern
                    </v-btn>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-form>
          </v-col>
        </v-row>
      </v-container>

      <v-snackbar
        v-model="helpers.snackbar.model"
        class="pb-4"
        bottom
        app
        timeout="2000"
        ><v-icon
          :color="helpers.snackbar.type === 'success' ? 'success' : 'error'"
          class="mr-1"
          >{{
            helpers.snackbar.type === "success"
              ? "mdi-check-circle"
              : "mdi-error"
          }}</v-icon
        >
        {{ helpers.snackbar.message }}
      </v-snackbar>
    </section>
  </div>
</template>

<script>
import { db, auth, FieldValue } from "@/firebase";
import { handleFirestoreError } from "@/util/firebase/FirebaseErrorHandling.js";

export default {
  name: "member-management-personnel-records-details-contact",
  components: {},
  data() {
    return {
      data: {
        // personal contact
        personal: {
          address: {
            street: null,
            streetNumber: null,
            postalCode: null,
            city: null,
          },
          phone: null,
          mobile: null,
          email: null,
        },
        // business contact
        business: {
          name: null,
          address: {
            street: null,
            streetNumber: null,
            postalCode: null,
            city: null,
            country: null,
          },
          phone: null,
          mobile: null,
          email: null,
        },
        // station contact
        station: {
          phone: null,
          mobile: null,
          email: null,
        },
      },
      helpers: {
        snackbar: {
          model: false,
          type: "success",
          message: "",
        },
        personalContactInfoForm: {
          isValid: false,
          isLoading: false,
        },
        businessContactInfoForm: {
          isValid: false,
          isLoading: false,
        },
        stationContactInfoForm: {
          isValid: false,
          isLoading: false,
        },
      },
      rules: {
        email: (value) => {
          if (!value) return true;
          const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          return pattern.test(value) || "Ungültige E-Mail-Adresse.";
        },
        mobile: (value) => {
          if (!value) return true;
          const pattern = /^[0-9]{10,14}$/;
          return pattern.test(value) || "Ungültige Telefonnummer.";
        },
        postalCode: (value) => {
          if (!value) return true;
          const pattern = /^[0-9]{5}$/;
          return pattern.test(value) || "Ungültige Postleitzahl.";
        },
      },
    };
  },
  computed: {},
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("personnelRecords_contact")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.data = doc.data();
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    },
    updatePersonalContactInfo() {
      this.helpers.personalContactInfoForm.isLoading = true;
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("personnelRecords_contact")
        .doc(this.$route.params.itemId)
        .update({
          personal: {
            address: {
              street: this.data.personal.address.street,
              streetNumber: this.data.personal.address.streetNumber,
              postalCode: this.data.personal.address.postalCode,
              city: this.data.personal.address.city,
            },
            phone: this.data.personal.phone,
            mobile: this.data.personal.mobile,
            email: this.data.personal.email,
          },
          "meta.lastUpdatedAt": FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": auth.currentUser.uid,
        })
        .then(() => {
          this.helpers.snackbar.message = "Aktualisierung erfolgreich.";
          this.helpers.snackbar.type = "success";
          this.helpers.personalContactInfoForm.isLoading = false;
          this.helpers.snackbar.model = true;
        })
        .catch((error) => {
          console.log(error.code, error.message);
          this.helpers.snackbar.message = handleFirestoreError(error);
          this.helpers.snackbar.type = "error";
          this.helpers.personalContactInfoForm.isLoading = false;
          this.helpers.snackbar.model = true;
        });
    },
    updateBusinessContactInfo() {
      this.helpers.businessContactInfoForm.isLoading = true;
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("personnelRecords_contact")
        .doc(this.$route.params.itemId)
        .update({
          business: {
            name: this.data.business.name,
            address: {
              street: this.data.business.address.street,
              streetNumber: this.data.business.address.streetNumber,
              postalCode: this.data.business.address.postalCode,
              city: this.data.business.address.city,
            },
            phone: this.data.business.phone,
            mobile: this.data.business.mobile,
            email: this.data.business.email,
          },
          "meta.lastUpdatedAt": FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": auth.currentUser.uid,
        })
        .then(() => {
          this.helpers.snackbar.message = "Aktualisierung erfolgreich.";
          this.helpers.snackbar.type = "success";
          this.helpers.businessContactInfoForm.isLoading = false;
          this.helpers.snackbar.model = true;
        })
        .catch((error) => {
          console.log(error.code, error.message);
          this.helpers.snackbar.message = handleFirestoreError(error);
          this.helpers.snackbar.type = "error";
          this.helpers.businessContactInfoForm.isLoading = false;
          this.helpers.snackbar.model = true;
        });
    },
    updateStationContactInfo() {
      this.helpers.stationContactInfoForm.isLoading = true;
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("personnelRecords_contact")
        .doc(this.$route.params.itemId)
        .update({
          station: {
            phone: this.data.station.phone,
            mobile: this.data.station.mobile,
            email: this.data.station.email,
          },
          "meta.lastUpdatedAt": FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": auth.currentUser.uid,
        })
        .then(() => {
          this.helpers.snackbar.message = "Aktualisierung erfolgreich.";
          this.helpers.snackbar.type = "success";
          this.helpers.stationContactInfoForm.isLoading = false;
          this.helpers.snackbar.model = true;
        })
        .catch((error) => {
          console.log(error.code, error.message);
          this.helpers.snackbar.message = handleFirestoreError(error);
          this.helpers.snackbar.type = "error";
          this.helpers.stationContactInfoForm.isLoading = false;
          this.helpers.snackbar.model = true;
        });
    },
  },
};
</script>

<style></style>
