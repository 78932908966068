<template>
  <div>
    <NavigationBar
      titel="Details"
      :actions="actions"
      :backRoute="backRoute"
      :tabs="tabs"
      :activeTab="activeTab"
      @active-tab-updated="activeTab = $event"
      showBackBtn
    ></NavigationBar>
    <router-view />
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
// import Subheader from "@/components/_systemwide/Subheader.vue";

import {
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_update,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_GENERAL_update,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CONTACT_update,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CHRONICLE_update,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CERTIFICATES_update,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_PHYSICALS_update,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_EMERGENCY_CONTACTS_update,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_ACCOUNTING_update,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_YOUTH_GROUP_update,
} from "@/data/permission-types.js";

export default {
  name: "member-management-personnel-records-details",
  components: {
    NavigationBar,
    // Subheader,
  },
  data() {
    return {
      actions: [
        {
          title: "Bearbeiten",
          permissions: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_update}`,
          icon: "mdi-pencil",
          actionStyle: "textBtn",
          function: this.editItem,
        },
        {
          title: "Aktionen",
          icon: "mdi-dots-horizontal-circle",
          actionStyle: "textBtnMultiple",
          list: [
            {
              title: "E-Mail senden",
              icon: "mdi-email",
              disabled: true,
              function: this.createItem,
            },
            {
              title: "Datensatz sperren",
              icon: "mdi-lock",
              disabled: true,
              function: this.createMultiple,
            },
            { divider: true },
            {
              title: "Kontakt als vCard exportieren ",
              icon: "mdi-file-export",
              disabled: true,
              function: this.createFromCSV,
            },
            {
              title: "Drucken",
              icon: "mdi-printer",
              disabled: true,
              function: this.printItem,
            },
          ],
        },

        { divider: true },
        { actionStyle: "clipboardBtn" },
      ],

      activeTab: `general`,
    };
  },
  computed: {
    tabs() {
      const allTabs = [
        {
          id: 1,
          name: "Stammdaten",
          route: `general`,
          permission: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_GENERAL_update}`,
        },
        {
          id: 2,
          name: "Kontaktdaten",
          route: `contact`,
          permission: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CONTACT_update}`,
        },
        {
          id: 3,
          name: "Chronik",
          route: `chronicle`,
          permission: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CHRONICLE_update}`,
        },
        {
          id: 4,
          name: "Fristen",
          route: `deadlines`,
          disabled: true,
          permission: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CHRONICLE_update}`,
        },
        {
          id: 5,
          name: "Fertigkeiten",
          route: `certificates`,
          permission: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CERTIFICATES_update}`,
        },
        {
          id: 6,
          name: "Untersuchungen",
          route: `physicals`,
          disabled: true,
          permission: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_PHYSICALS_update}`,
        },
        {
          id: 7,
          name: "Notfallkontakte",
          route: `emergency-contacts`,
          permission: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_EMERGENCY_CONTACTS_update}`,
        },
        { id: 11, name: "Inventar", route: `inventory`, disabled: true },
        {
          id: 12,
          name: "Abrechnung",
          route: `accounting`,
          disabled: true,
          permission: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_ACCOUNTING_update}`,
        },
        {
          id: 13,
          name: "Jugendfeuerwehr",
          route: `youth-group`,
          collectionId: "personnelRecords_youthGroup",
          disabled: true,
          permission: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_YOUTH_GROUP_update}`,
        },
        { id: 99, name: "Änderungshistorie", route: ``, disabled: true },
      ];
      return allTabs.filter((tab) => {
        return this.checkPermission(tab.permission);
      });
    },
    backRoute() {
      return this.$route.path.includes ===
        "/personnel-records/" + this.$route.params.itemId
        ? "member-management-personnel-records"
        : "";
    },
  },
  methods: {
    editItem() {
      this.$router.push({
        name: `member-management-personnel-records-edit-${
          this.activeTab || "general"
        }`,
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,
        },
      });
    },
    checkPermission(item) {
      if (item == "" || item == undefined) {
        return true;
      } else {
        return this.$store.getters["organization/checkPermissionByID"](item);
      }
    },
  },
};
</script>

<style></style>
