var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',{staticClass:"body-2 v-card__subtitle"},[_c('v-icon',{staticClass:"mr-2",attrs:{"left":"","small":""}},[_vm._v("mdi-card-account-details")]),_vm._v(_vm._s(_vm.type === "personal" ? "Privat" : _vm.type === "business" ? "Geschäftlich (Arbeitgeber)" : "Dienstlich"))],1),_c('v-card-text',[_c('v-row',{staticClass:"match-height",attrs:{"dense":""}},[(!_vm.hideAdress)?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-card',{attrs:{"outlined":""}},[(_vm.noAdressData)?_c('v-card-text',[_vm._v(" Keine Adresse hinterlegt ")]):_c('v-card-text',[_vm._v(" "+_vm._s(_vm.contact.name)+" "),(_vm.contact.name)?_c('br'):_vm._e(),_vm._v(" "+_vm._s(_vm.contact.address.street)+", "+_vm._s(_vm.contact.address.streetNumber)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.contact.address.postalCode)+" "+_vm._s(_vm.contact.address.city)+" "),_c('br'),_c('v-btn',{staticClass:"mt-2",attrs:{"small":"","depressed":"","href":'https://maps.apple.com/?q=' +
                _vm.contact.address.street +
                ' ' +
                _vm.contact.address.streetNumber +
                ' ' +
                _vm.contact.address.postalCode +
                ' ' +
                _vm.contact.address.city,"target":"_blank","rel":"noopener noreferrer"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-map-marker-circle")]),_vm._v("In Apple Maps öffnen")],1),_c('v-btn',{staticClass:"mt-2",attrs:{"small":"","depressed":"","href":'https://www.google.com/maps/search/' +
                _vm.contact.address.street +
                ' ' +
                _vm.contact.address.streetNumber +
                ' ' +
                _vm.contact.address.postalCode +
                ' ' +
                _vm.contact.address.city,"target":"_blank","rel":"noopener noreferrer"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-google-maps")]),_vm._v("In Google Maps öffnen")],1)],1)],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"12","md":!_vm.hideAdress ? '6' : '12'}},[_c('v-card',{attrs:{"outlined":""}},[(_vm.noAdressData)?_c('v-card-text',[_vm._v(" Keine Erreichbarkeit hinterlegt ")]):_c('v-card-text',[(_vm.contact.phone)?_c('v-list-item',[_c('v-list-item-icon',{staticClass:"mr-6"},[_c('v-icon',[_vm._v("mdi-phone")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_c('a',{staticClass:"text-decoration-none",attrs:{"href":'tel:' + _vm.contact.phone}},[_vm._v(_vm._s(_vm.phoneNumberFormatter(_vm.contact.phone)))])])],1)],1):_vm._e(),(_vm.contact.mobile)?_c('v-list-item',[_c('v-list-item-icon',{staticClass:"mr-6"},[_c('v-icon',[_vm._v("mdi-cellphone")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_c('a',{staticClass:"text-decoration-none",attrs:{"href":'tel:' + _vm.contact.mobile}},[_vm._v(_vm._s(_vm.phoneNumberFormatter(_vm.contact.mobile)))])])],1)],1):_vm._e(),(_vm.contact.email)?_c('v-list-item',[_c('v-list-item-icon',{staticClass:"mr-6"},[_c('v-icon',[_vm._v("mdi-at")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_c('a',{staticClass:"text-decoration-none",attrs:{"href":'mailto:' + _vm.contact.email}},[_vm._v(_vm._s(_vm.contact.email))])])],1)],1):_vm._e(),(_vm.contact.fax)?_c('v-list-item',[_c('v-list-item-icon',{staticClass:"mr-6"},[_c('v-icon',[_vm._v("mdi-fax")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.phoneNumberFormatter(_vm.contact.fax)))])],1)],1):_vm._e()],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }