<template>
  <data-chip
    :text="getLabel(type)"
    :color="getColor(type)"
    :iconRight="getIcon(type)"
    :small="small"
  >
  </data-chip>
</template>

<script>
import DataChip from "@/components/_systemwide/DataChip.vue";
export default {
  name: "personnel-record-chronicle-type-chip",
  components: {
    DataChip,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chronicleEventTypeOptions: [
        { icon: "mdi-import", text: "Eintritt", value: "entry", color: "red" },
        {
          icon: "mdi-run-fast",
          text: "Beurlaubung",
          value: "leave",
          color: "red",
        },
        { icon: "mdi-export", text: "Austritt", value: "exit", color: "red" },
        {
          icon: "mdi-chevron-double-up",
          text: "Beförderung",
          value: "up",
          color: "red",
        },
        {
          icon: "mdi-chevron-double-down",
          text: "Degradierung",
          value: "down",
          color: "red",
        },
        { icon: "mdi-star", text: "Ehrung", value: "honor", color: "red" },
        {
          icon: "mdi-badge-account",
          text: "Diensstellung",
          value: "position",
          color: "red",
        },
        {
          icon: "mdi-dots-horizontal-circle",
          text: "Sonstiges Ereignis",
          value: "other",
          color: "red",
        },
      ],
    };
  },
  methods: {
    getAttribute(type, attribute) {
      const option = this.chronicleEventTypeOptions.find(
        (option) => option.value === type
      );
      return option ? option[attribute] : null;
    },
    getLabel(type) {
      return this.getAttribute(type, "text");
    },
    getColor(type) {
      return this.getAttribute(type, "color");
    },
    getIcon(type) {
      return this.getAttribute(type, "icon");
    },
  },
};
</script>
