<template>
  <div>
    <section>
      <template>
        <v-container>
          <v-row class="match-height">
            <v-col cols="12" sm="12" md="6">
              <v-skeleton-loader
                v-if="dataIsLoading"
                elevation="2"
                type="card, list-item-avatar-three-line, list-item-avatar-three-line"
              ></v-skeleton-loader>
              <personnel-record-contact-card
                v-else
                type="personal"
                :contact="data.personal"
              ></personnel-record-contact-card>
            </v-col>

            <v-col cols="12" sm="12" md="6">
              <v-skeleton-loader
                v-if="dataIsLoading"
                elevation="2"
                type="card, list-item-avatar-three-line, list-item-avatar-three-line"
              ></v-skeleton-loader>
              <personnel-record-contact-card
                v-else
                type="business"
                :contact="data.business"
              ></personnel-record-contact-card>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-skeleton-loader
                v-if="dataIsLoading"
                elevation="2"
                type="card, list-item-avatar-three-line, list-item-avatar-three-line"
              ></v-skeleton-loader>
              <personnel-record-contact-card
                v-else
                type="station"
                hideAdress
                :contact="data.station"
              ></personnel-record-contact-card>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import { db } from "@/firebase";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import PersonnelRecordContactCard from "@/components/member-management/personnel-records/PersonnelRecordContactCard.vue";
export default {
  name: "member-management-personnel-records-details-contact",
  components: {
    PersonnelRecordContactCard,
    SupportTools,
  },
  data() {
    return {
      dataIsLoading: true,
      data: null,
    };
  },
  computed: {
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [{ title: "data", data: this.data }];
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("personnelRecords_contact")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.data = doc.data();
            this.dataIsLoading = false;
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
  },
};
</script>

<style></style>
