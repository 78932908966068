<template>
  <div>
    <v-data-table
      v-model="chronicleEventSelectionLocal"
      :headers="headers"
      :items="chronicleEvents"
      no-data-text="Keine Ereignisse vorhanden"
      sort-by="name"
      show-expand
      item-key="meta.id"
      single-expand
      :expanded.sync="expanded"
      :show-select="mode === 'manage'"
    >
      <template v-slot:top>
        <slot name="top">
          <v-card flat>
            <v-card-title class="body-2 v-card__subtitle"
              ><v-icon left small class="mr-2">mdi-history</v-icon>Ereignisse
            </v-card-title>
          </v-card>
        </slot>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-card-text v-if="item.note">
            <!-- <div v-if="item.note" class="font-weight-medium">Bemerkung:</div> -->
            <!-- <div v-if="item.note">{{ item.note }}</div> -->
          </v-card-text>
          <v-card-text>Keine ergänzenden Informationen verfügbar.</v-card-text>
        </td>
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <personnel-record-chronicle-type-chip
          :type="item.type"
          small
        ></personnel-record-chronicle-type-chip>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ timestampConverter(item.date) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon @click="editChronicleEvent(item)"
          ><v-icon>mdi-pencil</v-icon></v-btn
        >
        <v-btn icon @click="deleteChronicleEvent(item)"
          ><v-icon>mdi-delete</v-icon></v-btn
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
import PersonnelRecordChronicleTypeChip from "@/components/member-management/personnel-records/PersonnelRecordChronicleTypeChip.vue";
export default {
  name: "personnel-records-data-table",
  props: {
    chronicleEvents: {
      type: Array,
      required: true,
    },
    mode: {
      type: String,
      default: "new",
      required: true,
      validator: function (value) {
        return ["view", "manage"].indexOf(value) !== -1;
      },
    },
  },
  components: { PersonnelRecordChronicleTypeChip },
  model: {
    prop: "chronicleEventsSelection",
    event: "chronicleEventsSelectionChange",
  },
  data() {
    return {
      expanded: [],
    };
  },
  computed: {
    chronicleEventsSelectionLocal: {
      get: function () {
        return this.chronicleEventsSelection;
      },
      set: function (value) {
        this.$emit("chronicleEventsSelectionChange", value);
      },
    },
    headers() {
      let headers = [
        { text: "Typ", value: "type" },
        { text: "Bezeichnung", value: "text" },
        { text: "Datum", value: "date" },
        { text: "Bemerkung", value: "note" },
        { text: "", value: "data-table-expand", sortable: false },
      ];

      if (this.mode === "manage") {
        headers.push({ text: "Aktionen", value: "actions", sortable: false });
      }

      return headers;
    },
  },
  methods: {
    editChronicleEvent(chronicleEvent) {
      this.$emit("editChronicleEvent", chronicleEvent);
    },
    deleteChronicleEvent(chronicleEvent) {
      this.$emit("deleteChronicleEvent", chronicleEvent);
    },
    timestampConverter(timestamp) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };

      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
  },
};
</script>
