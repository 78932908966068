<template>
  <div>
    <v-data-table
      v-model="courseSelectionLocal"
      :headers="headers"
      :items="courses"
      no-data-text="Keine Lehrgänge zugewiesen"
      sort-by="duration.proofDate"
      show-expand
      item-key="meta.id"
      single-expand
      :expanded.sync="expanded"
      :show-select="mode === 'manage'"
    >
      <template v-slot:top>
        <slot name="top">
          <v-card flat>
            <v-card-title class="body-2 v-card__subtitle"
              ><v-icon left small class="mr-2">mdi-book-education</v-icon
              >Lehrgänge (Nachweise)
            </v-card-title>
          </v-card>
        </slot>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-card-text
            v-if="
              (item.duration.startDate && item.duration.endDate) ||
              item.externalName ||
              item.externalId ||
              item.note
            "
          >
            <div
              v-if="item.duration.startDate && item.duration.endDate"
              class="font-weight-medium"
            >
              Lehrgangsdauer:
            </div>
            <div v-if="item.duration.startDate && item.duration.endDate">
              {{ timestampConverter(item.duration.startDate) }} bis
              {{ timestampConverter(item.duration.endDate) }}
            </div>
            <div v-if="item.externalName" class="font-weight-medium">
              Lehrgangsbezeichnung:
            </div>
            <div v-if="item.externalName">{{ item.externalName }}</div>
            <div v-if="item.externalId" class="font-weight-medium">
              Lehrgangsnummer:
            </div>
            <div v-if="item.externalId">{{ item.externalId }}</div>
            <div v-if="item.note" class="font-weight-medium">Bemerkung:</div>
            <div v-if="item.note">{{ item.note }}</div>
          </v-card-text>
          <v-card-text v-else
            >Keine ergänzenden Informationen verfügbar.</v-card-text
          >
        </td>
      </template>
      <template v-slot:[`item.course.group`]="{ item }">
        <data-chip
          :text="item.course.group.title"
          :color="item.course.group.color"
          :iconLeft="item.course.group.icon"
          small
        ></data-chip>
      </template>
      <template v-slot:[`item.duration.startDate`]="{ item }">
        {{ timestampConverter(item.duration.startDate) }}
      </template>
      <template v-slot:[`item.duration.endDate`]="{ item }">
        {{ timestampConverter(item.duration.endDate) }}
      </template>
      <template v-slot:[`item.duration.proofDate`]="{ item }">
        {{ timestampConverter(item.duration.proofDate) }}
      </template>
      <template v-slot:[`item.rating`]="{ item }">
        <data-chip :text="getRatingText(item.rating)" small></data-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon @click="editCourse(item)"
          ><v-icon>mdi-pencil</v-icon></v-btn
        >
        <v-btn icon @click="deleteCourse(item)"
          ><v-icon>mdi-delete</v-icon></v-btn
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DataChip from "@/components/_systemwide/DataChip.vue";
export default {
  name: "personnel-records-courses-data-table",
  props: {
    courseSelection: {},
    courses: {
      type: Array,
      required: true,
    },
    mode: {
      type: String,
      default: "new",
      required: true,
      validator: function (value) {
        return ["view", "manage"].indexOf(value) !== -1;
      },
    },
  },
  components: { DataChip },
  model: {
    prop: "courseSelection",
    event: "courseSelectionChange",
  },
  data() {
    return {
      expanded: [],
    };
  },
  computed: {
    courseSelectionLocal: {
      get: function () {
        return this.courseSelection;
      },
      set: function (value) {
        this.$emit("courseSelectionChange", value);
      },
    },
    headers() {
      let headers = [
        { text: "Lehrgang", value: "course.title" },
        { text: "Lehrgangsgruppe", value: "course.group" },
        // { text: "Beginn", value: "duration.startDate" },
        // { text: "Ende", value: "duration.endDate" },
        { text: "Nachweisdatum", value: "duration.proofDate" },
        { text: "Bewertung", value: "rating" },
        { text: "", value: "data-table-expand", sortable: false },
      ];

      if (this.mode === "manage") {
        headers.push({ text: "Aktionen", value: "actions", sortable: false });
      }

      return headers;
    },
  },
  methods: {
    editCourse(course) {
      this.$emit("editCourse", course);
    },
    deleteCourse(course) {
      this.$emit("deleteCourse", course);
    },
    getRatingText(rating) {
      switch (rating) {
        case "passed":
          return "Bestanden";
        case "failed":
          return "Nicht bestanden";
        case "aborted":
          return "Abgebrochen";
        case "unrated":
          return "Nicht bewertet";
        default:
          return "Nicht bewertet";
      }
    },
    timestampConverter(timestamp) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };

      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
  },
};
</script>
