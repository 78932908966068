<template>
  <data-chip
    :text="statusText(status)"
    :color="statusColor(status)"
    :small="small"
  >
  </data-chip>
</template>

<script>
import DataChip from "@/components/_systemwide/DataChip.vue";
export default {
  name: "personnel-record-status-chip",
  components: {
    DataChip,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    statusText(statusId) {
      switch (statusId) {
        case "active":
          return "Aktiv";
        case "onLeave":
          return "Beurlaubt";
        case "resigned":
          return "Ausgetreten";
        case "transfer":
          return "Im Übertritt";
        case "dismissed":
          return "Entlassen";
        case "deceased":
          return "Verstorben";
        default:
          return "Fehler";
      }
    },
    statusColor(status) {
      switch (status) {
        case "active":
          return "#4CAF50";
        case "onLeave":
          return "#FF9800";
        case "resigned":
          return "#795548";
        case "transfer":
          return "#CDDC39";
        case "dismissed":
          return "#F44336";
        case "deceased":
          return "#9E9E9E";
        default:
          return "";
      }
    },
  },
};
</script>
