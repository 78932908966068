<template>
  <div>
    <v-data-table
      v-model="emergencyContactSelectionLocal"
      :headers="headers"
      :items="emergencyContacts"
      no-data-text="Keine Notfallkontakte vorhanden"
      sort-by="name"
      show-expand
      item-key="meta.id"
      single-expand
      :expanded.sync="expanded"
      :show-select="mode === 'manage'"
    >
      <template v-slot:top>
        <slot name="top">
          <v-card flat>
            <v-card-title class="body-2 v-card__subtitle"
              ><v-icon left small class="mr-2">mdi-shield-account</v-icon
              >Notfallkontakte
            </v-card-title>
          </v-card>
        </slot>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        {{ item.firstName }} {{ item.lastName }}
      </template>
      <template v-slot:[`item.relationship`]="{ item }">
        <data-chip
          :text="getRelationshipText(item.relationship)"
          small
        ></data-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon @click="editEmergencyContact(item)"
          ><v-icon>mdi-pencil</v-icon></v-btn
        >
        <v-btn icon @click="deleteEmergencyContact(item)"
          ><v-icon>mdi-delete</v-icon></v-btn
        >
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-card-text
            v-if="
              item.mobile ||
              item.email ||
              item.phone ||
              item.address.street ||
              item.address.streetNumber ||
              item.address.postalCode ||
              item.address.city ||
              item.address.country
            "
          >
            <div v-if="item.phone" class="font-weight-medium">Telefon:</div>
            <div v-if="item.phone">
              {{ item.phone }}
            </div>
            <div v-if="item.mobile" class="font-weight-medium">Mobil:</div>
            <div v-if="item.mobile">
              {{ item.mobile }}
            </div>
            <div v-if="item.email" class="font-weight-medium">E-Mail:</div>
            <div v-if="item.email">
              {{ item.email }}
            </div>
            <div
              v-if="
                item.address.street ||
                item.address.streetNumber ||
                item.address.postalCode ||
                item.address.city ||
                item.address.country
              "
              class="font-weight-medium"
            >
              Adresse:
            </div>
            <div
              v-if="
                item.address.street ||
                item.address.streetNumber ||
                item.address.postalCode ||
                item.address.city ||
                item.address.country
              "
            >
              {{ item.address.street }}, {{ item.address.streetNumber }}
              <br />
              {{ item.address.postalCode }} {{ item.address.city }}
              <br />
              {{ item.address.country }}
            </div>
          </v-card-text>
          <v-card-text v-else
            >Keine ergänzenden Informationen verfügbar.</v-card-text
          >
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DataChip from "@/components/_systemwide/DataChip.vue";
export default {
  name: "personnel-record-emergency-contacts-data-table",
  props: {
    emergencyContactSelection: {},
    emergencyContacts: {
      type: Array,
      required: true,
    },
    mode: {
      type: String,
      default: "new",
      required: true,
      validator: function (value) {
        return ["view", "manage"].indexOf(value) !== -1;
      },
    },
  },
  components: { DataChip },
  data() {
    return {
      expanded: [],
    };
  },
  model: {
    prop: "emergencyContactSelection",
    event: "emergencyContactSelectionChange",
  },
  computed: {
    emergencyContactSelectionLocal: {
      get: function () {
        return this.emergencyContactSelection;
      },
      set: function (value) {
        this.$emit("emergencyContactSelectionChange", value);
      },
    },
    headers() {
      let headers = [
        {
          text: "Name",
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: "Beziehung",
          sortable: true,
          value: "relationship",
        },
        { text: "Bemerkung", value: "note" },
        {
          text: "",
          value: "data-table-expand",
          sortable: false,
        },
      ];

      if (this.mode === "manage") {
        headers.push({
          text: "Aktionen",
          value: "actions",
          sortable: false,
          align: "end",
        });
      }

      return headers;
    },
  },
  methods: {
    editEmergencyContact(emergencyContact) {
      this.$emit("editEmergencyContact", emergencyContact);
    },
    deleteEmergencyContact(emergencyContact) {
      this.$emit("deleteEmergencyContact", emergencyContact);
    },
    getRelationshipText(relationship) {
      switch (relationship) {
        case "parent":
          return "Elternteil";
        case "spouse":
          return "Ehepartner";
        case "child":
          return "Kind";
        case "sibling":
          return "Geschwister";
        case "friend":
          return "Freund";
        case "colleague":
          return "Arbeitskollege";
        case "neighbor":
          return "Nachbar";
        case "other":
          return "Sonstige";
        default:
          return "Unbekannt";
      }
    },
  },
};
</script>
