<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      min-width="350px"
      max-width="450px"
      max-height="800px"
      offset-y
      left
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn depressed v-bind="attrs" v-on="on">
          <v-icon>mdi-view-column</v-icon>
        </v-btn>
      </template>

      <v-card outlined>
        <v-card-title class="font-weight-medium"
          ><span class="text-subtitle-1 font-weight-medium"
            >Spalten auswählen</span
          >
          <v-spacer></v-spacer>
          <v-btn
            text
            small
            color="primary"
            :disabled="defaultState"
            @click="reset()"
            >Zurücksetzen</v-btn
          >
        </v-card-title>
        <v-card-subtitle>
          <v-text-field
            v-model="search"
            outlined
            dense
            label="Spalten durchsuchen"
            hide-details="auto"
            prepend-inner-icon="mdi-magnify"
            class="mt-2"
            clearable
          ></v-text-field>
        </v-card-subtitle>
        <v-divider></v-divider>
        <v-data-table
          v-model="selectionLocalBuffer"
          :headers="listHeaders"
          :items="headers"
          :search="search"
          show-select
          item-key="value"
          dense
          checkbox-color="primary"
          mobile-breakpoint="0"
          disable-pagination
          disable-sort
          hide-default-footer
        ></v-data-table>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small color="primary" @click="close()">Abbrechen</v-btn>
          <v-btn depressed color="primary" small @click="apply()">
            Anwenden
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<style></style>

<script>
export default {
  name: "table-column-selector-menu",
  props: {
    selection: {},

    headers: {
      type: Array,
      required: false,
      default: () => [
        {
          sortKey: 1,
          text: "",
          value: "title",
          align: "start",
          default: true,
        },
      ],
    },
  },
  model: {
    prop: "selection",
    event: "selectionChange",
  },
  computed: {
    selectionLocal: {
      get: function () {
        return this.selection;
      },
      set: function (value) {
        this.$emit("selectionChange", value);
      },
    },
    defaultHeaders() {
      return this.headers.filter((header) => header.default);
    },
    defaultState() {
      return (
        this.selectionLocalBuffer.length === this.defaultHeaders.length &&
        this.selectionLocalBuffer.every((item) => item.default)
      );
    },
  },
  data() {
    return {
      menu: false,
      search: "",
      selectionLocalBuffer: [],
      listHeaders: [
        {
          text: "Titel",
          value: "text",
          align: "start",
        },
      ],
    };
  },
  created() {
    this.selectionLocalBuffer = this.selectionLocal;
  },
  methods: {
    close() {
      this.menu = false;
    },
    apply() {
      this.selectionLocalBuffer.sort((a, b) =>
        a.sortKey > b.sortKey ? 1 : -1
      );
      this.selectionLocal = this.selectionLocalBuffer;
      this.menu = false;
    },
    reset() {
      this.selectionLocalBuffer = this.defaultHeaders;
      this.selectionLocal = this.defaultHeaders;
    },
  },
};
</script>
