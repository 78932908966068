<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <v-card-subtitle
            ><v-icon left small class="mr-2">mdi-card-account-details</v-icon
            >Allgemeine Pflichtangaben</v-card-subtitle
          >
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model.trim="personnelRecordBaseLocal.firstName"
                  label="Vorname"
                  hide-details="auto"
                  :rules="[rules.required]"
                  outlined
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model.trim="personnelRecordBaseLocal.lastName"
                  label="Nachname"
                  hide-details="auto"
                  :rules="[rules.required]"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card outlined>
          <v-card-subtitle
            ><v-icon left small class="mr-2">mdi-card-account-details</v-icon
            >Profileigenschaften</v-card-subtitle
          >
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model.trim="personnelRecordBaseLocal.personnelNumber"
                  label="Personalnummer"
                  hide-details="auto"
                  outlined
                  prepend-inner-icon="mdi-identifier"
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="personnelRecordBaseLocal.status"
                  :items="statusOptions"
                  label="Status"
                  :rules="[rules.required]"
                  hide-details="auto"
                  outlined
                >
                  <template v-slot:selection="{ item }">
                    <personnel-record-status-chip
                      :status="item.text"
                    ></personnel-record-status-chip>
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12" sm="6">
                <v-select
                  v-model="personnelRecordBaseLocal.division"
                  label="Dienstgruppe"
                  hide-details="auto"
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PersonnelRecordStatusChip from "@/components/member-management/personnel-records/PersonnelRecordStatusChip.vue";
export default {
  name: "personnel-record-creation-editor",
  props: {
    personnelRecordBase: {},
  },
  components: {
    PersonnelRecordStatusChip,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
      statusOptions: [
        { text: "Aktiv", value: "Aktiv" },
        { text: "Im Übertritt", value: "Im Übertritt" },
        { text: "Beurlaubt", value: "Beurlaubt" },
        { text: "Ausgetreten", value: "Ausgetreten" },
        { text: "Entlassen", value: "Entlassen" },
        { text: "Verstorben", value: "Verstorben" },
      ],
    };
  },
  model: {
    prop: "personnelRecordBase",
    event: "personnelRecordBaseChange",
  },
  computed: {
    personnelRecordBaseLocal: {
      get: function () {
        return this.personnelRecordBase;
      },
      set: function (value) {
        this.$emit("personnelRecordBaseChange", value);
      },
    },
  },
  methods: {},
};
</script>
