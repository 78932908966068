<template>
  <div>
    <v-data-table
      :headers="tableHeaders"
      :items="personnelRecords"
      item-key="user.uid"
      :search="search"
      multi-sort
      sort-by="lastName"
      :sort-desc="false"
      :footer-props="footerProps"
      class="elevation-2"
      max-height="800px auto"
      @click:row="routeDetails"
      no-data-text="Keine Personen vorhanden"
      no-results-text="Keine Personen gefunden"
    >
      <!-- show-select -->
      <template v-slot:top>
        <table-header
          :activeDataViewValue="activeDataView"
          @active-data-view-updated="updateDataView($event)"
          :dataViews="dataViews"
          :searchValue="search"
          @search-updated="search = $event"
          showTableColumnSelector
          :allColumnHeaders="headers"
          :tableHeaders="tableHeaders"
          @table-headers-updated="tableHeaders = $event"
        ></table-header>
      </template>
      <template v-slot:[`item.firstName`]="{ item }">
        <v-avatar
          v-if="item.user.photoUrl"
          size="36"
          :color="
            $vuetify.theme.dark ? 'rgba(255, 255, 255, 0.12)' : 'grey lighten-2'
          "
          rounded
          class="font-weight-medium my-2"
          ><v-img contain :src="item.user.photoUrl"></v-img
        ></v-avatar>
        <v-avatar
          v-else
          size="36"
          :color="
            $vuetify.theme.dark ? 'rgba(255, 255, 255, 0.12)' : 'grey lighten-2'
          "
          rounded
          class="font-weight-medium my-2"
          >{{ initials(item.firstName + " " + item.lastName) }}</v-avatar
        >

        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align: middle"
        >
          <span class="d-block font-weight-medium"
            >{{ item.title }} {{ item.firstName }} {{ item.lastName }}</span
          >
          <!-- <small class="d-block grey--text">Test Test</small> -->
        </div>
      </template>
      <template v-slot:[`item.personnelNumber`]="{ item }">
        <pre>{{ item.personnelNumber }}</pre>
      </template>
      <template v-slot:[`item.rank.title`]="{ item }">
        <v-chip outlined label v-if="item.rank.title">
          <img
            v-if="item.rank.badgeUrl.size70x70"
            :src="item.rank.badgeUrl.size70x70"
            contain
            height="20px"
            class="mr-2 py-1"
          />
          <span class="font-weight-medium">{{ item.rank.shortTitle }}</span>
        </v-chip>
      </template>
      <template v-slot:[`item.dutyPosition.title`]="{ item }">
        <v-chip outlined label v-if="item.dutyPosition.title">
          <img
            v-if="item.dutyPosition.badgeUrl.size70x70"
            :src="item.dutyPosition.badgeUrl.size70x70"
            contain
            height="30px"
            class="mr-2 py-1"
          />
          <span class="font-weight-medium">{{
            item.dutyPosition.shortTitle
          }}</span>
        </v-chip>
      </template>
      <template v-slot:[`item.birthdate`]="{ item }">
        <span v-if="item.birthdate"
          >{{ timestampConverter(item.birthdate) }} ({{
            getAge(item.birthdate.toDate())
          }})</span
        >
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <personnel-record-status-chip :status="item.status" small>
        </personnel-record-status-chip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import TableHeader from "@/components/_system/helpers/TableHeader.vue";
import PersonnelRecordStatusChip from "@/components/member-management/personnel-records/PersonnelRecordStatusChip.vue";
export default {
  name: "personnel-records-data-table",
  props: {
    personnelRecords: {
      type: Array,
      required: true,
    },
  },
  components: {
    TableHeader,
    PersonnelRecordStatusChip,
  },
  data() {
    return {
      search: "",
      leavesPersonFilter: "own",
      leavesStateFilter: "active",
      footerProps: {
        "items-per-page-options": [15, 25, 50, 100, -1],
      },
      activeDataView: "all",
      dataViews: [
        { text: "Alle", value: "all" },
        { text: "Aktiv", value: "active" },
        { text: "Beurlaubt", value: "inactive" },
        { text: "Im Übertritt", value: "transfer" },
        { text: "Entlassen", value: "dismissed" },
        { text: "Verstorben", value: "deceased" },
      ],
      statusOptions: [
        { text: "Aktiv", value: "Aktiv" },
        { text: "Im Übertritt", value: "Im Übertritt" },
        { text: "Beurlaubt", value: "Beurlaubt" },
        { text: "Ausgetreten", value: "Ausgetreten" },
        { text: "Entlassen", value: "Entlassen" },
        { text: "Verstorben", value: "Verstorben" },
      ],
      headers: [
        {
          sortKey: 0,
          text: "Personalnummer",
          value: "personnelNumber",
          group: "Stammdaten",
          default: true,
        },
        {
          sortKey: 1,
          text: "Name",
          value: "firstName",
          group: "Stammdaten",
          default: true,
        },
        {
          sortKey: 2,
          text: "Dienstgrad",
          value: "rank.title",
          group: "Stammdaten",
          default: true,
          align: "center",
        },
        {
          sortKey: 3,
          text: "Position",
          value: "dutyPosition.title",
          group: "Stammdaten",
          default: true,
          align: "center",
        },
        {
          sortKey: 4,
          text: "Abteilung",
          value: "division.title",
          group: "Stammdaten",
          default: true,
        },
        {
          sortKey: 5,
          text: "Geburtsdatum",
          value: "birthdate",
          group: "Stammdaten",
          default: true,
        },
        {
          sortKey: 6,
          text: "Geburtsort",
          value: "birthplace",
          group: "Stammdaten",
          default: false,
        },
        {
          sortKey: 7,
          text: "Status",
          value: "status",
          group: "Stammdaten",
          default: true,
          filter: (value) => {
            if (!this.activeDataView) return true;
            if (this.activeDataView === "all") return true;
            return value === this.activeDataView;
          },
        },
        // {
        //   sortKey: 8,
        //   text: "Straße, Nr.",
        //   value: "contact.adress.street",
        //   group: "Kontaktdaten",
        //   default: false,
        // },
        // {
        //   sortKey: 9,
        //   text: "Postleitahl",
        //   value: "contact.adress.zipCode",
        //   group: "Kontaktdaten",
        //   default: false,
        // },
        // {
        //   sortKey: 10,
        //   text: "Stadt",
        //   value: "contact.adress.city",
        //   group: "Kontaktdaten",
        //   default: false,
        // },
        // {
        //   sortKey: 11,
        //   text: "Telefon (privat)",
        //   value: "contact.phone",
        //   group: "Kontaktdaten",
        //   default: false,
        // },
      ],
      tableHeaders: [
        {
          sortKey: 1,
          text: "Name",
          value: "firstName",
          group: "Stammdaten",
          default: true,
        },
        {
          sortKey: 2,
          text: "Dienstgrad",
          value: "rank.title",
          group: "Stammdaten",
          default: true,
        },
        {
          sortKey: 3,
          text: "Position",
          value: "dutyPosition.title",
          group: "Stammdaten",
          default: true,
        },
        {
          sortKey: 4,
          text: "Abteilung",
          value: "division.title",
          group: "Stammdaten",
          default: true,
        },
        {
          sortKey: 5,
          text: "Geburtsdatum",
          value: "birthdate",
          group: "Stammdaten",
          default: true,
        },
        {
          sortKey: 7,
          text: "Status",
          value: "status",
          group: "Stammdaten",
          default: true,
          filter: (value) => {
            if (!this.activeDataView) return true;
            if (this.activeDataView === "all") return true;
            return value === this.activeDataView;
          },
        },
      ],
    };
  },
  methods: {
    updateDataView(value) {
      this.activeDataView = value;
    },
    getAge(birthdate) {
      return new Date().getFullYear() - new Date(birthdate).getFullYear();
    },
    timestampConverter(timestamp) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },

    routeDetails(item) {
      this.$router.push({
        name: "member-management-personnel-records-details-general",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item.user.uid,
        },
      });
    },
    initials(fullName) {
      const namesArray = fullName.trim().split(" ");
      if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
      else
        return `${namesArray[0].charAt(0)}${namesArray[
          namesArray.length - 1
        ].charAt(0)}`;
    },
  },
};
</script>
