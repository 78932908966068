<template>
  <div>
    <section>
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="mt-7">
              <v-card>
                <personnel-record-chronicle-data-table
                  :chronicleEvents="chronicleEvents"
                  mode="manage"
                >
                  <template v-slot:top>
                    <v-card flat>
                      <v-card-title class="body-2 v-card__subtitle"
                        ><v-icon left small class="mr-2">mdi-history</v-icon
                        >Ereignisse

                        <v-spacer></v-spacer>
                        <!-- <v-btn
                          depressed
                          :disabled="!helpers.chronicleEventsSelection.length"
                          color="error"
                          class="mr-2"
                          @click="deleteChronicleEventsSelection()"
                          ><v-icon left>mdi-delete</v-icon>Auswahl löschen
                        </v-btn> -->
                        <v-menu
                          v-model="helpers.typeSelector.model"
                          :nudge-width="150"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              depressed
                              color="success"
                              class="ml-4"
                              v-bind="attrs"
                              v-on="on"
                              ><v-icon left>mdi-plus</v-icon>Ereignis hinzufügen
                            </v-btn>
                          </template>
                          <v-card>
                            <v-list nav>
                              <v-list-item
                                v-for="(
                                  option, index
                                ) in chronicleEventTypeOptions"
                                :key="index"
                                @click="toggleChronicleEventFormDialog(option)"
                              >
                                <v-list-item-title>
                                  <v-icon class="mr-3">{{ option.icon }}</v-icon
                                  >{{ option.text }}</v-list-item-title
                                >
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-menu>
                      </v-card-title>
                    </v-card>
                  </template>
                </personnel-record-chronicle-data-table>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <custom-dialog
        v-if="helpers.chronicleEventForm.dialogModel"
        @close="toggleChronicleEventFormDialog(null)"
        title="Ereignis hinzufügen"
        width="m"
        :btnAction="saveChronicleEvent"
        btnText="XXX"
        :btnDisabled="!helpers.chronicleEventForm.formIsValid"
        btnColor="success"
      >
        <template v-slot:titleChip
          ><v-chip label class="ml-4 font-weight-medium"
            ><v-icon left>{{ helpers.chronicleEventForm.nextType.icon }}</v-icon
            >{{ helpers.chronicleEventForm.nextType.text }}</v-chip
          ></template
        >
        <template v-slot:content>
          <v-form
            v-model="helpers.chronicleEventForm.formIsValid"
            @submit.prevent="saveChronicleEvent()"
            class="mt-2"
          >
            <v-row>
              <v-col cols="12">
                <v-card outlined>
                  <v-card-subtitle
                    ><v-icon left small class="mr-2">mdi-database</v-icon
                    >Eingaben</v-card-subtitle
                  >
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          label="Ereignisdatum"
                          hide-details="auto"
                          outlined
                          type="date"
                          prepend-inner-icon="mdi-calendar"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <!-- v-model.trim="" -->
                        <v-textarea
                          label="Bemerkungen"
                          hide-details="auto"
                          outlined
                          auto-grow
                          :rows="3"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </template>
      </custom-dialog>
    </section>
  </div>
</template>

<script>
import PersonnelRecordChronicleDataTable from "@/components/member-management/personnel-records/PersonnelRecordChronicleDataTable.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
export default {
  name: "member-management-personnel-records-edit-chronicle",

  components: { PersonnelRecordChronicleDataTable, CustomDialog },
  data() {
    return {
      chronicleEvents: [],
      chronicleEventTypeOptions: [
        { icon: "mdi-import", text: "Eintritt", value: "entry" },
        { icon: "mdi-run-fast", text: "Beurlaubung", value: "leave" },
        { icon: "mdi-export", text: "Austritt", value: "exit" },
        { icon: "mdi-chevron-double-up", text: "Beförderung", value: "up" },
        {
          icon: "mdi-chevron-double-down",
          text: "Degradierung",
          value: "down",
        },
        { icon: "mdi-star", text: "Ehrung", value: "honor" },
        { icon: "mdi-badge-account", text: "Diensstellung", value: "position" },
        {
          icon: "mdi-dots-horizontal-circle",
          text: "Sonstiges Ereignis",
          value: "other",
        },
      ],

      helpers: {
        chronicleEventForm: {
          dialogModel: false,
          formIsValid: false,
        },
        typeSelector: {
          model: false,
          nextType: "",
          isValid: false,
        },
      },
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
    };
  },
  methods: {
    toggleChronicleEventFormDialog(option) {
      this.helpers.chronicleEventForm.dialogModel =
        !this.helpers.chronicleEventForm.dialogModel;
      if (option === null) {
        this.helpers.chronicleEventForm.nextType = null;
      } else {
        this.helpers.chronicleEventForm.nextType = option;
      }
    },
    saveChronicleEvent() {},
  },
};
</script>
