<template>
  <div>
    <section>
      <template>
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="mt-7">
                <v-card>
                  <!-- TODO: Add data to table -->
                  <personnel-record-chronicle-data-table
                    :chronicleEvents="[]"
                    mode="view"
                  >
                  </personnel-record-chronicle-data-table>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
import PersonnelRecordChronicleDataTable from "@/components/member-management/personnel-records/PersonnelRecordChronicleDataTable.vue";
export default {
  name: "member-management-personnel-records-details-chronicle",
  components: {
    PersonnelRecordChronicleDataTable,
  },
  data() {
    return {};
  },
};
</script>

<style></style>
