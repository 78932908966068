var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.emergencyContacts,"no-data-text":"Keine Notfallkontakte vorhanden","sort-by":"name","show-expand":"","item-key":"meta.id","single-expand":"","expanded":_vm.expanded,"show-select":_vm.mode === 'manage'},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_vm._t("top",function(){return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"body-2 v-card__subtitle"},[_c('v-icon',{staticClass:"mr-2",attrs:{"left":"","small":""}},[_vm._v("mdi-shield-account")]),_vm._v("Notfallkontakte ")],1)],1)]})]},proxy:true},{key:`item.name`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")]}},{key:`item.relationship`,fn:function({ item }){return [_c('data-chip',{attrs:{"text":_vm.getRelationshipText(item.relationship),"small":""}})]}},{key:`item.actions`,fn:function({ item }){return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.editEmergencyContact(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteEmergencyContact(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[(
            item.mobile ||
            item.email ||
            item.phone ||
            item.address.street ||
            item.address.streetNumber ||
            item.address.postalCode ||
            item.address.city ||
            item.address.country
          )?_c('v-card-text',[(item.phone)?_c('div',{staticClass:"font-weight-medium"},[_vm._v("Telefon:")]):_vm._e(),(item.phone)?_c('div',[_vm._v(" "+_vm._s(item.phone)+" ")]):_vm._e(),(item.mobile)?_c('div',{staticClass:"font-weight-medium"},[_vm._v("Mobil:")]):_vm._e(),(item.mobile)?_c('div',[_vm._v(" "+_vm._s(item.mobile)+" ")]):_vm._e(),(item.email)?_c('div',{staticClass:"font-weight-medium"},[_vm._v("E-Mail:")]):_vm._e(),(item.email)?_c('div',[_vm._v(" "+_vm._s(item.email)+" ")]):_vm._e(),(
              item.address.street ||
              item.address.streetNumber ||
              item.address.postalCode ||
              item.address.city ||
              item.address.country
            )?_c('div',{staticClass:"font-weight-medium"},[_vm._v(" Adresse: ")]):_vm._e(),(
              item.address.street ||
              item.address.streetNumber ||
              item.address.postalCode ||
              item.address.city ||
              item.address.country
            )?_c('div',[_vm._v(" "+_vm._s(item.address.street)+", "+_vm._s(item.address.streetNumber)+" "),_c('br'),_vm._v(" "+_vm._s(item.address.postalCode)+" "+_vm._s(item.address.city)+" "),_c('br'),_vm._v(" "+_vm._s(item.address.country)+" ")]):_vm._e()]):_c('v-card-text',[_vm._v("Keine ergänzenden Informationen verfügbar.")])],1)]}}],null,true),model:{value:(_vm.emergencyContactSelectionLocal),callback:function ($$v) {_vm.emergencyContactSelectionLocal=$$v},expression:"emergencyContactSelectionLocal"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }