<template>
  <div>
    <section>
      <v-form> </v-form>
    </section>
  </div>
</template>

<script>
export default {
  name: "member-management-personnel-records-edit-accounting",
  components: {},
  data() {
    return {};
  },
};
</script>

<style></style>
