<template>
  <div>
    <v-data-table
      v-model="skillSelectionLocal"
      :headers="headers"
      :items="skills"
      no-data-text="Keine Qualifikationen zugewiesen"
      sort-by="status"
      show-expand
      item-key="meta.id"
      single-expand
      :expanded.sync="expanded"
      :show-select="mode === 'manage'"
    >
      <template v-slot:top>
        <slot name="top">
          <v-card flat>
            <v-card-title class="body-2 v-card__subtitle"
              ><v-icon left small class="mr-2">mdi-label</v-icon>Qualifikationen
            </v-card-title>
          </v-card>
        </slot>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-card-text v-if="item.note">
            <div v-if="item.note" class="font-weight-medium">Bemerkung:</div>
            <div v-if="item.note">{{ item.note }}</div>
          </v-card-text>
          <v-card-text v-else
            >Keine ergänzenden Informationen verfügbar.</v-card-text
          >
        </td>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <data-chip
          :text="
            getStatusText(item.qualification.since, item.qualification.until)
          "
          small
        ></data-chip>
      </template>
      <template v-slot:[`item.skill.badgeUrl`]="{ item }">
        <img
          v-if="item.skill.badgeUrl.size200x200"
          :src="item.skill.badgeUrl.size200x200"
          contain
          height="50px"
          class="pa-2 justify-center align-center"
        />
      </template>
      <template v-slot:[`item.qualification.since`]="{ item }">
        {{
          item.qualification.since
            ? timestampConverter(item.qualification.since)
            : null
        }}
      </template>
      <template v-slot:[`item.qualification.until`]="{ item }">
        {{
          item.qualification.until
            ? timestampConverter(item.qualification.until)
            : null
        }}
      </template>
      <template v-slot:[`item.skill.group.id`]="{ item }">
        <data-chip
          :text="item.skill.group.title"
          :color="item.skill.group.color"
          :iconLeft="item.skill.group.icon"
          small
        ></data-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon @click="editSkill(item)"><v-icon>mdi-pencil</v-icon></v-btn>
        <v-btn icon @click="deleteSkill(item)"
          ><v-icon>mdi-delete</v-icon></v-btn
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DataChip from "@/components/_systemwide/DataChip.vue";
export default {
  name: "personnel-records-skills-data-table",
  props: {
    skillSelection: {},
    skills: {
      type: Array,
      required: true,
    },
    mode: {
      type: String,
      default: "new",
      required: true,
      validator: function (value) {
        return ["view", "manage"].indexOf(value) !== -1;
      },
    },
  },
  components: { DataChip },
  model: {
    prop: "skillSelection",
    event: "skillSelectionChange",
  },
  data() {
    return {
      expanded: [],
    };
  },
  computed: {
    skillSelectionLocal: {
      get: function () {
        return this.skillSelection;
      },
      set: function (value) {
        this.$emit("skillSelectionChange", value);
      },
    },
    headers() {
      let headers = [
        { text: "", value: "skill.badgeUrl", sortable: false },
        { text: "Qualifikation", value: "skill.title" },
        { text: "Gruppe", value: "skill.group.id" },
        { text: "Qualifikation seit", value: "qualification.since" },
        { text: "Qualifikation bis", value: "qualification.until" },
        { text: "Status", value: "status" },
        { text: "", value: "data-table-expand", sortable: false },
      ];

      if (this.mode === "manage") {
        headers.push({ text: "Aktionen", value: "actions", sortable: false });
      }

      return headers;
    },
  },
  methods: {
    editSkill(skill) {
      this.$emit("editSkill", skill);
    },
    deleteSkill(skill) {
      this.$emit("deleteSkill", skill);
    },
    getStatusText(qualificationSince, qualificationUntil) {
      const currentDate = new Date();
      const sinceDate = qualificationSince ? qualificationSince.toDate() : null;
      const untilDate = qualificationUntil ? qualificationUntil.toDate() : null;

      if (sinceDate && sinceDate > currentDate) {
        return "Vorgemerkt";
      } else if (untilDate && untilDate < currentDate) {
        return "Abgelaufen";
      } else {
        return "Aktiv";
      }
    },
    timestampConverter(timestamp) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };

      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
  },
};
</script>
