<template>
  <div>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <v-card>Text</v-card>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
export default {
  name: "member-management-personnel-records-details-changelog",
  props: {
    personnelRecord: Object,
  },
  data() {
    return {};
  },
};
</script>

<style></style>
